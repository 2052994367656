module.exports = {
    title: {
        login: 'Login - iFi',
        logout: 'Logout - iFi',
        hrnavigation: 'Navigation - iFi',
        chips: 'Chips',
        iqtest: 'Standford-Binet IQ Test',
        bigfive: 'The BIG FIVE Survey',
        bigfive_results: 'Big Five - Results',
        lanesheet: 'Approval Report',
        approvalform: 'Approval Forms',
        iq_results: 'IQ - Results',
        keys: 'Keys',
        notfound: '404 Not found',
        console: 'Dashboard - 360',
        result: 'Result - iFi',
        activitylist: 'My activities - iFi'
    },
    notify: {
        title_server: 'Server',
        title_retry: 'Retry',
        title_notification: 'Notification',
        title_err_type1: 'Error code: -1',
        title_err_type2: 'Error code: 500',
        title_err_type_ill: 'Illegal operation',
        content_refused: 'Your request was refused.',
        content_server: 'Server timed out cause: ',
        content_loginerr: 'Incorrect username or password',
        content_ill: 'Action illegally',
        result_null: 'No more data.',
        dt_token_outofdate: 'Dingtalk information is out of date, try once again.',
        content_key_err: 'Input a correct key.',
        content_success: 'Success.',
        content_reported: 'Reported',
        cotent_all_items_required: 'All items are required.'
    },
    text: {
        placeholder: 'Input...',
        placeholder_select: 'Select one',
        placeholder_pick_day: 'Pick a day',
        message_saved: 'Saved',
        message_deleted: 'Deleted',
        message_nochange: 'No change',
        message_delete_confirm: 'Would you want wo delete it?',
        sex_man: 'Man',
        sex_woman: 'Woman',
        text_delete_message_title: 'Delete?',
        text_canceled: 'Canceled',
        text_yes: 'Yes',
        text_no: 'No',
        text_notset: 'Not set',
        text_confirm_title: 'Confirm',
        btn_refresh: 'Refresh',
        text_complete: 'Compeleted',
        text_incomplete: 'Unfinished',
        text_to_be_started: 'To be started',
        btn_print: 'Print',
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
        six: '6',
        seven: '7',
        eight: '8',
        nine: '9',
        ten: '10',
        eleven: '11',
        identical: 'Identical',
        not_available: 'No available',
        inused: 'In used',
        notused: 'Be not used',
        text_powered_by: '⚡ Powered by iFi Team.',
        second: 'Second(s)',
        minute: 'Minute(s)',
        record: 'records(s).',
        unused: 'Available',
        inusing: 'In using',
        used: 'Expired',
        keyword: 'Key',
        status: 'Status',
        key_title: 'Need a key',
        option: 'Option',
        reset_confirm_body: 'This key will be reset, continue?',
        expire_confirm_body: 'This key will be expired, continue?',
    },
    common: {
        notify_import: 'Disabled',
        notity_title_message: 'Message',
        notify_title_error: 'Error',
        notify_empty_assets: '500 error in server, connect your administrator',
        btn_revise: 'Edit',
        btn_delete: 'Delete',
        btn_import: 'Import',
        btn_save: 'Save',
        btn_message_confirm: 'Confirm',
        btn_message_cancel: 'Cancel'
    },
    hrnavigation: {
        top_title: 'Internal use only',
        btn_toiqresults: 'View IQ records',
        btn_tobigfiveresults: 'View Big five records',
        btn_to_360: 'To login 360'
    },
    login: {
        placehoder_username: 'Username...',
        placehoder_password: 'Password...',
        btn_login: 'Login',
        notify_username_null: 'Username is required',
        notify_password_null: 'Password is required',
        notify_usernamepassword_lengtherror: 'Username or password is illegal',
        loadingviadingtalk: 'Waiting for dingtalk...',
        notify_dingtalk_not_bound: 'You do not bind Dingtalk, open management page or contact your administrator please.',
        auth_err: 'Authorized failed, tay again later.'
    },
    logout: {
        title_logout: 'Logout success',
        btn_tologin: 'To login'
    },
    notfound: {
        title_notfound: 'This page is not found - 404',
        subtitle_notfound: 'Failed to reach the page.'
    },
    chips: {
        mpn: 'mpn',
        k3id: 'Material id',
        country: 'Country',
        distributor: 'Distributor',
        moq: 'MOQ',
        sku: 'SKU',
        stock: 'Stock',
        updated: 'Updated',
        count_stock: 'Total stock',
        span_updated: 'Last updated：',
        notify_chiptemplate_null: 'Template is null.',
        notify_chipdata_partly: 'Some data not update.',
        tip_apichip_update: 'Click to update',
        text_updatefromapi_body: 'We will update data from 3-rd party, parts Limit will be consumed, continue?'
    },
    iqtest: {
        tip_top1: 'This test is customized for you who is over 11 years old.',
        tip_top2: 'To answer the following 60 questions in 45 minutes and you can calculate with pen and paper.',
        title_iqs: 'IQ Askform',
        content_title_ask_age: 'How old are you?',
        age_1: 'age 11 and younger',
        age_2: '11 Years old',
        age_3: '12 Years old',
        age_4: '13 Years old',
        age_5: '14 Years old',
        age_6: '15 Years old',
        age_7: 'age 16 and older',
        notify_age_unselected: 'Age is unselected!',
        btn_submit: 'Submit now!',
        notify_unfinished_number1: 'No. ',
        notify_unfinished_number2: ' is unselected.',
        text_rs_score: 'Your score'
    },
    iqresults: {
        top_title: 'IQ - Results',
        score: 'Score',
        duration: 'T.C.',
        label_match_post: 'Matching position',
        label_standard_score_range: 'Standard range',
        label_anal_result: 'Result',
        text_higher: 'Higher than standard',
        text_normal: 'Normal',
        text_slightly_lower: 'Slightly lower than standard',
        text_lower: 'Lower than standard'

    },
    bigfive: {
        title_ask_info: 'TIPS',
        content_ask_info: 'Be careful to fill in information:',
        label_fullname: 'Your Name',
        label_post: 'Job Title',
        notify_ask_unfinished: 'Name and post are required.',
        toptext_1: '',
        toptext_2: ' question(s) remaining.',
        option_1: 'Very Inaccurate',
        option_2: 'Moderately Inaccurate',
        option_3: 'Neither Accurate nor Inaccurate',
        option_4: 'Moderately Accurate',
        option_5: 'Very Accurate',
        btn_redo: 'Redo',
        askform_title: 'THE BIG FIVE',
        top_tip_text: 'In ten minutes without a second thought.',
        top_tip_text2: 'Be true to yourself, not what is expected of you.',
        top_tip_text3: 'Try to avoid neutral answers.',
        // top_tip_text: '请仔细阅读下列各题，并在右边适当的数字上画圈，以表示您对每一题目赞同或不赞同的程度。',
        targetN: 'Neuroticism',
        targetE: 'Extraversion',
        targetO: 'Openness',
        targetA: 'Agreebleness',
        targetC: 'Conscientiousness',
        explain_title_1: 'Neuroticism',
        explain_title_2: 'Extraversion',
        explain_title_3: 'Openness',
        explain_title_4: 'Agreeableness',
        explain_title_5: 'Conscientiousness',
        explain_content_1_1: 'Neuroticism refers to the tendency of individuals to experience negative emotions. Individuals with high scores on the neuroticism dimension are more prone to experiencing negative emotions such as anger, anxiety, and depression. They react more intensely to external stimuli, have poorer emotional regulation abilities, and often find themselves in a state of distress. Moreover, these individuals tend to have poorer cognitive abilities, decision-making skills, and coping mechanisms for external pressures.',
        explain_content_1_2: 'On the other hand, individuals with low scores on the neuroticism dimension experience fewer worries, are less emotional, and tend to be calmer. However, this does not necessarily mean that they frequently experience positive emotions; the frequency of positive emotional experiences is primarily associated with extraversion.',
        explain_content_1_3: '',
        explain_content_2_1: 'Extraversion represents the energy invested in the external world. Highly extraverted individuals enjoy social interactions, are energetic, and often experience positive emotions. They are enthusiastic, enjoy activities, and seek thrills and adventures. Within a group, they are talkative, confident, and enjoy attracting attention from others.',
        explain_content_2_2: 'On the other hand, introverted individuals are quieter, cautious, and prefer less interaction with the outside world. Their reluctance to engage with others is not necessarily due to shyness or depression but simply because they do not require as much stimulation as highly extraverted individuals do, hence they prefer solitude. The quiet nature of introverted individuals is sometimes mistaken for arrogance or unfriendliness, but upon closer interaction, one often discovers that they are actually very kind individuals.',
        explain_content_2_3: '',
        explain_content_3_1: "Openness describes a person's cognitive style. Individuals with high scores in openness are imaginative and creative, curious, appreciate art, and are sensitive to beauty. They prefer abstract thinking and have a wide range of interests. People high in openness are suited for professions such as teaching.",
        explain_content_3_2: 'On the other hand, individuals with low scores in openness (i.e., closedness) are practical, prefer routine, and are more traditional and conservative. Closed individuals are suitable for professions such as policing, sales, and service-oriented roles.',
        explain_content_3_3: '',
        explain_content_4_1: 'Agreeableness represents "love" and emphasizes cooperation and interpersonal harmony.',
        explain_content_4_2: 'Individuals with high levels of agreeableness are empathetic, friendly, generous, and helpful, willing to sacrifice their own interests for others. They hold an optimistic view of human nature and believe in the inherent goodness of people.',
        explain_content_4_3: "On the other hand, individuals with low levels of agreeableness prioritize their own interests above others'. Essentially, they are indifferent to the interests of others and are unwilling to help. Sometimes, they are very suspicious of others and doubt their motives. For certain positions, excessive agreeableness is unnecessary, especially in situations that require firmness and objective judgment, such as scientists, critics, and soldiers.",
        explain_content_5_1: 'Conscientiousness refers to the way we control, manage, and regulate our impulses.',
        explain_content_5_2: 'Individuals with high conscientiousness tend to avoid trouble and are more likely to achieve greater success. People generally perceive highly conscientious individuals as intelligent and reliable, but they may also be perfectionists or workaholics. Extremely conscientious individuals can appear dull, boring, and lacking in vitality.',
        explain_content_5_3: 'On the other hand, individuals with low conscientiousness are often seen as happy, fun, and good companions. However, impulsive behavior often leads to trouble for themselves. Although it may bring temporary satisfaction to the individual, it can also result in long-term adverse consequences such as harming others or substance abuse. Individuals with low conscientiousness typically do not achieve great success. However, impulsiveness is not always negative; sometimes, the environment requires us to make quick decisions.'
    },
    bigfiveresults: {
        top_title: 'Big Five Results',
        survey_name: 'Name',
        survey_post: 'Position',
        survey_time: 'Time',
        empty_results_discription: 'No results yet.',
        alert_notice_results: 'Click name to view result.'
    },
    lanesheet: {
        transfer_application: 'Approval of Probationary Application',
        job_transfer_application: 'Job Transfer Application',
        resignation_handover_report: 'Resignation Handover Report',
        employee: 'Employee Name',
        title: 'Title',
        department: 'Department',
        dateOfEntry: 'Entry Date',
        transferDate: 'Transfer Date',
        btn_report: 'Submit',
        newdept: 'New Department',
        newtitle: 'New Title',
        dateOfTransferred: 'Transferred Date',
        approver1: 'Original Department Approver',
        approver2: 'Transferred Department Approver',
        reason: 'Reason for Transfer',
        superior: 'Superior Name',
        created_time: 'Created time',
        checked: 'Checked'
    },
    console: {
        menu_title: 'Dashboard',
        menu_question: ' Question',
        menu_questionnaire: ' Questionnaire',
        menu_activity: ' Activity',
        menu_properties: ' Resource',
        menu_staff: ' Employee',
        submenu_target: 'Indicator',
        submenu_dept: 'Department',
        submenu_weight: 'Weight',
        router_logout: 'Logout'
    },
    question: {
        tab_selector: 'Multiple-choice questions',
        tab_subjective: 'Subjective questions',
        span_countquestion: ' question(s) in question base.',
        btn_importquestion: 'Import',
        btn_addquestion: 'Create new question',
        label_target: 'Indicator',
        label_target_en: 'Indicator(English)',
        label_number: 'Number',
        label_content: 'Content',
        label_content_en: 'Content(English)',
        label_isused: 'Status',
        label_options: 'Option',
        notify_questionnull: 'No question has been found.',
        notify_questiontargetnull: 'No indicator has been found, create it first.',
        title_add_question: 'Create question',
        title_revise_question: 'Revise question',
        btn_save: 'Save',
        notify_incompletely: 'Question information incompletely',
        message_delete_question_confirm: 'Some Questionnaires that used it will be disabled if you delete it. Are you sure?'
    },
    questionnaire: {
        span_countquestionnaire: ' questionnaire(s).',
        btn_addquestionnaire: 'Create new questionnaire',
        notify_questionnairenull: 'No questionnaire has been found',
        label_questionnaire_title: 'Name',
        label_questionnaire_title_en: 'Name(English)',
        title_edit_questionnaire: 'Edit questionnaire',
        title_allquestions: 'All questions',
        title_selectedquestions: 'Seleted questions',
        tag_questions_deleted_notice: 'Invalid question in box is disabled, It can not be removed if it was used for questionnaire. Create a new questionnaire to solve it.',
        tag_sort_notice: 'Questions will sort by your selecting sequence.',
        btn_preview: 'Preview',
        notify_preview_later: 'Failed to open it. look over it on examination page.',
        notify_some_saved_err: 'Failed to save some questions, check it manually.',
        notify_questionnaire_incompletely: 'Questionnaire information incompletely',
        notify_quesioninquesitonnairenull: 'Select one question at least',
        title_add_questionnaire: 'Create questionnaire',
        notify_delete_questionnaire: 'Some Activties that used it will be disabled if you delete it. Are you sure?',
        alert_noticerevise_body: 'It makes a difference if you revise a questionnaire that belongs to an activity, do it carefully!'
    },
    target: {
        span_counttarget: ' indicator(s).',
        btn_exporttp: 'Export Template',
        btn_addtarget: 'Create new indicator',
        label_target: 'Indicator',
        label_target_en: 'Indicator(English)',
        label_options: 'Option',
        title_add_target: 'Create indicator',
        btn_save: 'Save',
        notify_incompletely: 'Indicator information incompletely',
    },
    dept: {
        span_countdept: ' department(s).',
        btn_adddept: 'Create new dept',
        label_dept: 'Name',
        label_dept_en: 'Name(English)',
        label_options: 'Option',
        notify_deptnull: 'No dept has been found.',
        title_add_dept: 'Create a dept',
        btn_save: 'Save',
        notify_incompletely: 'Indicator information incompletely',
    },
    staff: {
        span_countstaff: ' employee(s).',
        btn_addstaff: 'Create new employee',
        btn_synfromdt: 'Synchronize from Dingtalk',
        btn_importonjob: 'Save to system',
        label_username: 'Username',
        label_password: 'Password',
        label_dept: 'Department',
        label_name: 'Full name',
        label_englishname: 'English name',
        label_sex: 'Gender',
        label_dept_name: 'Department',
        label_ismanager: 'Has subordinate',
        label_options: 'Option',
        label_dt_userid: 'Dingtalk userid',
        label_dt_name: 'Real name',
        label_matchuser: 'Matching dingtalk user',
        notify_staffnull: 'No employee has been found',
        title_add_staff: 'Add employee',
        title_syn_dtuser: 'On job employee',
        title_modify_staff: 'Modify employee',
        btn_save: 'Save',
        notify_incompletely: 'Staff information incompletely',
        notify_username_password_length: 'Username and password must be between 3,15 characters',
        alert_staff_use_body: "Set employee who can tack part in activities you have been hold. Press 'Ctrl' + 'F' to search.",
        alert_staff_tip_body: '为避免用户重复，自动匹配创建功能暂未开启，请手动创建账号以新增员工，然后手动匹配。',
        notify_userexist_err: 'Username exist or registed',
        text_delete_staff_body: 'Do you want to remove it?',
        text_synfromdt_body: 'It will be downloading address book form Dingtalk, it will be took some time...',
        text_import_onjob_body: 'It will be importing employee to system, you can match them later if someone are not set real name.',
        notify_import_onjob_success: 'Import success and skip existing.',
        notify_import_warn: 'Some users import failed.',
        notify_import_nochange: 'All of users you selected are existing already.',
        notify_no_onjobuser: "No records found, synchronize from Dingtalk first."
    },
    activity: {
        notify_actnull: 'No activity has been found.',
        span_countactivity: ' activity/activities.',
        alert_notice_errorquestionnaire_body: 'Results of evaluation will incorrect if questionnaire is not match.',
        btn_addactivity: 'Create new activity',
        label_activity_title: 'Activity name',
        label_options: 'Option',
        label_process: 'Progress',
        btn_primary: 'Primary',
        btn_questionnaire: 'Questionnaire',
        btn_participant: 'Employee',
        btn_relations: 'Relationship',
        title_add_activity: 'Create activity',
        label_activity: 'Name',
        label_activity_en: 'Name(English)',
        btn_save: 'Save',
        notify_incompletely: 'Activity information incompletely.',
        title_revise_activity: 'Revise activity',
        title_set_questionnaire: 'Set questionnaire',
        alert_disabled_set_questionnaire_title: 'This information change opportunity is limited to once.',
        alert_disabled_set_questionnaire_body: 'This information is read only if you were already selected questionnaire.',
        alert_disabled_seteparticipat_title: 'This information change opportunity is limited to once.',
        alert_disabled_seteparticipat_body: 'This information is read only if you were already selected object of evaluation.',
        notify_questionnaire_err_apart: 'Request is not complete, check your questionnaires of activity',
        text_delete_message_body: 'This information change opportunity is limited to once, submit it now?',
        text_participant_message_body: 'Object of evaluation change opportunity is limited to once, submit it now?',
        text_start_activity_body: 'All setting of activity will be disabled if you start it, do it now?',
        title_allquestionnaires: 'All questionnaire',
        title_selectedquestionnaires: 'Selected questionnaire',
        title_set_participant: 'Employee',
        title_allparticipant: 'All Staff',
        title_selectedparticipant: 'Participant',
        notify_questionnaireinactnull: 'Select one questionnaire at least',
        notify_participant_err_apart: 'Request is not complete, check your participants of activity',
        notify_participant_null_relations: 'You should set paricipants first before set relations.',
        notify_questionnaire_null_relations: 'You should set questionnaire first before set relations.',
        title_set_relations: 'Relationship',
        label_participant: 'Employee',
        label_questionnaire_relations: 'Questionnaire',
        label_superior: 'Superior',
        label_equal: 'Equal',
        label_subordinate: 'Subordinate',
        label_self: 'Need self-assessment',
        label_result: 'Result',
        title_revise_relation: 'Edit relationship',
        notify_questionnaire_self_null: 'You must set questionnaire and self-appraisal, do not submit illegally!',
        notify_relations_least: 'Set one of superior, colleague, subordinate at least.',
        notify_relations_err_apart: 'Request is not complete, check your relations of activity',
        notify_super_null_err: 'An item of superior is unselected.',
        notify_equal_null_err: 'An item of colleague is unselected.',
        notify_subordinate_null_err: 'An item of subordinate is unselected.',
        notify_relations_not_finish: 'Please check the relationship',
        title_incomplete_users: 'List of Remaining'
    },
    result: {
        main_title: 'Evaluation Report',
        staffname: 'Name:',
        dept_name: 'Department:',
        total_score: 'Score:',
        report_time: 'Report time:',
        preface_span1: 'It is the report of your 360 evaluation, includes what others think of you.',
        preface_h4_1: 'The report will help you:',
        preface_h4_2: 'To understand this report, and:',
        preface_ul_1_li_1: '1.To understand What others say about your ability to do your job.',
        preface_ul_1_li_2: '2.To know your capabilities and compare with others.',
        preface_ul_1_li_3: '3.To find your advantages and bring it into your job.',
        preface_ul_1_li_4: '4.To find your disadvantages and improve it.',
        preface_span2: 'Attending the trends of results and try to understand it.',
        preface_span3: 'Reading this report with a positive attitude.',
        preface_ul_2_li_1: '1.Keep your mind wide open.',
        preface_ul_2_li_2: '2.Everyone has strengths and weaknesses.',
        preface_ul_2_li_3: '3.It is business, not personal.',
        preface_ul_2_li_4: '4.Listen and understand to other ideas.',
        preface_ul_2_li_5: '5.Do not turn them away.',
        preface_ul_2_li_6: '6.Express your feelings and thoughts.',
        preface_ul_2_li_7: '7.Understand all of them and know how to improve.',
        lebel_score5: 'Extremely Accurate',
        lebel_score4: 'Very Accurate',
        lebel_score3: 'Somewhat Accurate',
        lebel_score2: 'Not very Accurate',
        lebel_score1: 'Not Accurate At all',
        explain_1: '1.Scoring rules are in the table above.',
        explain_2: '2.We will provide the average score with your superiors,colleagues,subordinates and yourself.',
        explain_3: "3.'Individual scores' with each indicator are the weighted average.",
        explain_4: "4.'Average score of company' is the weighted average all of all scores.",
        explain_5: '5.Score of Self-Assessment are comparison only.',
        explain_6: '6.Data sources and weights change automatically according to your job level.',
        tilte_preface: 'Preface',
        title_standard: 'Standard and Statistics',
        label_weight: 'Weight',
        title_target_s: 'Indicator analysis',
        title_info_source: 'Data source',
        label_target_name: 'Indicator',
        label_superior: 'Superior',
        label_equal: 'Colleague',
        label_subordinate: 'Subordinate',
        label_self: 'Yourself',
        label_all: 'Individual score',
        label_total: 'Total',
        label_score: 'Individual score',
        label_company_avg: 'Average score of company',
        chart_label_self: 'Self-Assessment',
        chart_label_weight: 'Individual score',
        chart_label_score: 'Individual score',
        chart_label_company_avg: 'Average score of company',
        notify_questionnaire_isnot_finished: 'This questionnaire is not finish.',
        target_type: 'Type',
        table_text_position: 'Where are you',
        title_target: 'Indicator:',
        target_solve_discription: "We collect your scores with each indicator in the following tables including superior's, colleague's and subordinate's(if any). And a simple picture will help you to understand them.",
        target_solve_discription_2: 'Discription3',
        title_cognition: 'Difference of cognition',
        cognition_discription: "Your indicators are distributed in the follwing 4 areas, focus on 'Potential Area' and 'Blind Area', other people's views differ from yours on these indicators.",
        cognition_li_1: 'cognition_li1',
        cognition_li_2: 'cognition_li2',
        cognition_li_3: 'cognition_li3',
        cognition_li_4: 'cognition_li4',
        cognition_card_title_1: 'Consensus of Strengths ',
        cognition_card_title_2: 'Potential Zone',
        cognition_card_title_3: 'Consensus to be improved',
        cognition_card_title_4: 'Blind Zone',
        title_behaviours: 'Your strengths and ablilities thar you can improve',
        advantage_discription_1: 'Your strengths: Top 5 individual scores(with their indicator).',
        advantage_discription_2: 'Your strengths: Top 7 individual scores(with their indicator).',
        improvable_discription_1: 'Ablilities that you can improve: Bottom 5 individual scores(with their indicator).',
        improvable_discription_2: 'Ablilities that you can improve: Bottom 7 individual scores(with their indicator).',
        label_behaviour_item: 'Item(Question/Indicator)',
        label_result_compare: 'Result/Comparison',
        label_difference: 'Difference',
        title_self_rating: 'Self-perception Biases',
        self_rating_discription: 'Self-perception Biases: Top 10 items with the highest individual score and Self-Assessment difference among all questions.',
        title_subjective_feedback: 'Open feedback',
        subjective_discription: 'Evaluators give you comments and advice on a number of issues to help you further understand the views of others and gain some inspiration on how to develop them.',
        label_question: 'Question',
        title_score: 'Score for each point'
    },
    activitylist: {
        welcome_prefix: 'Welcome, ',
        conplete_all_questionnaire_alert: 'All questionnaires have been finished.',
        activity_alert1: '',
        activity_alert2: ' questionnaire(s) below you have to fill in and they are anonymous.',
        label_activity: 'Project',
        label_be_evaluate: 'Name',
        label_type: 'Relationship',
        label_options: 'Go',
        label_status: 'Status',
        type_superior: 'Your superior',
        type_subordinate: 'Your subordinate',
        type_equal: 'Your colleague',
        type_self: 'Yourself',
        notify_no_activity: 'No activity at the moment.',
        empty_activity_discription: 'No activity at the moment.',
        router_result: 'Result',
        title_result: 'Your result',
        alert_resulttable: 'Show completed activities only.',
        label_option: 'View',
        notify_result_not_allow: 'Results are not allowed to view now.'
    },
    askform: {
        current_object: 'Name:',
        notify_questionoptions_null: '500 error in server, connect your administrator(Option)',
        btn_submit_askform: 'Submit',
        text_submit_questionnaire_body: 'Your answers will be saved, confirm or not?',
        notify_incomplete_answer: 'You have to finish all items!',
        notify_warn_apart: 'Got exceptions, contact you administrator',
        text_unfinished_qs: 'Unfinished items: '
    }
}